import React from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Hero from './features/Hero/Hero';
import Contact from './features/Contact/Contact';
import Modules from './features/Modules/Modules';
import BottomCTA from './features/BottomCTA/BottomCTA';
import './App.css';

function App() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container columnSpacing={0}>
        <Grid xs={12}>
          <Hero />
        </Grid>
        <Grid id="modules" xs={12}>
          <Modules />
        </Grid>
        <Grid id="contact" xs={12}>
          <Contact />
        </Grid>
        <Grid xs={12}>
          <BottomCTA />
        </Grid>
      </Grid>
    </Box>
  );
}

export default App;
