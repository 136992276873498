import {
  Typography, useMediaQuery, Stack, Divider, Button,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';
import SectionContainer from '../../components/SectionContainer';
import content from './ContactContent.json';

const ContactFormButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.contrastText,
  color: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.contrastText,
  border: '1px solid',
  width: useMediaQuery(theme.breakpoints.up('lg')) ? '250px' : '100%',
  variant: 'contained',
  boxShadow: theme.shadows[3],
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
  },
}));

function InterestedInQuestions() {
  const theme = useTheme();
  return (
    <Stack spacing={4}>
      <Stack direction="column">
        <Typography
          variant="h4"
          color={theme.palette.secondary.contrastText}
        >
          { content.interested }
        </Typography>
        <Typography
          variant="h6"
          color={theme.palette.secondary.contrastText}
        >
          { content.buildIt }
        </Typography>
      </Stack>
      <Stack direction="column">
        <Typography
          variant="h4"
          color={theme.palette.secondary.contrastText}
        >
          { content.infrastructure }
        </Typography>
        <Typography
          variant="h6"
          color={theme.palette.secondary.contrastText}
        >
          { content.manageIt }
        </Typography>
      </Stack>
    </Stack>
  );
}

function ContactForm() {
  return (
    <Stack spacing={4} direction="column">
      <ContactFormButton href="https://calendly.com/marlin-code/marlin-demo">Schedule a chat.</ContactFormButton>
    </Stack>
  );
}
function Contact() {
  const theme = useTheme();
  return (
    <SectionContainer sx={{ background: theme.palette.secondary.horizontalGradient }}>
      <Stack direction="column" spacing={8}>
        <Grid container alignItems="left" spacing={8}>
          <Grid xs={12} lg={6}>
            <Typography
              variant="h2"
              fontWeight={700}
              color={theme.palette.secondary.contrastText}
            >
              { content.somethingDifferent }
            </Typography>
          </Grid>
          <Grid xs={12} lg={6}>
            <InterestedInQuestions />
          </Grid>
        </Grid>
        <Divider variant="middle" sx={{ borderColor: theme.palette.secondary.contrastText }} />
        <Grid container alignItems="center" rowSpacing={4}>
          <Grid xs={12} lg={6}>
            <Typography
              variant="h2"
              fontWeight={700}
              color={theme.palette.secondary.contrastText}
            >
              { content.chat }
            </Typography>
          </Grid>
          <Grid xs={12} lg={6}>
            <ContactForm />
          </Grid>
        </Grid>
      </Stack>
    </SectionContainer>
  );
}

export default Contact;
