import {
  Typography, Box, Stack, useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import React from 'react';
import TransparentLogo from '../../assets/transparent-logo.svg';
import CallToActionButtonGroup from '../../components/CallToActionButtonGroup';
import content from './HeroContent.json';

import SectionContainer from '../../components/SectionContainer';

function CallToActionMission() {
  const theme = useTheme();
  return (
    <Stack direction="column" spacing={0}>
      <Typography
        variant="subtitle1"
        color={theme.palette.primary.contrastText}
      >
        { content.subHeadline }
      </Typography>
      <Typography
        variant="h1"
        fontWeight={500}
        color={theme.palette.primary.contrastText}
      >
        { content.headline }
      </Typography>
    </Stack>
  );
}

function CallToActionText() {
  const theme = useTheme();
  return (
    <Stack direction="column" spacing={8}>
      <CallToActionMission />
      <Typography
        variant="h6"
        color={theme.palette.primary.contrastText}
      >
        { content.mission }
      </Typography>
    </Stack>
  );
}

function CallToAction() {
  return (
    <Box flexGrow={1}>
      <Grid container rowSpacing={8}>
        <Grid xs={12} rowSpacing={4}>
          <CallToActionText />
        </Grid>
        <Grid xs={12}>
          <CallToActionButtonGroup variant="orange" />
        </Grid>
      </Grid>
    </Box>
  );
}

function Hero() {
  const theme = useTheme();
  return (
    <SectionContainer sx={{ background: theme.palette.primary.mainGradient }}>
      <Grid container alignItems="center">
        <Grid xs={12} lg={8}>
          <CallToAction />
        </Grid>
        {useMediaQuery(theme.breakpoints.up('lg')) && (
        <Grid container lg={4} justifyContent="center" alignContent="center">
          <img src={TransparentLogo} alt="" />
        </Grid>
        )}
      </Grid>
    </SectionContainer>
  );
}

export default Hero;
