import React, { useState } from 'react';
import { Stack, Button, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import TryTodayDialog from '../features/TryTodayDialog/TryTodayDialog';

const OrangeButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  borderColor: theme.palette.secondary.main,
  width: useMediaQuery(theme.breakpoints.up('lg')) ? '250px' : '100%',
  height: '45px',
  variant: 'contained',
  boxShadow: theme.shadows[3],
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const GreenButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderColor: theme.palette.primary.main,
  width: useMediaQuery(theme.breakpoints.up('lg')) ? '250px' : '100%',
  height: '45px',
  variant: 'contained',
  boxShadow: theme.shadows[3],
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const InvertedButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  color: theme.palette.primary.main,
  width: useMediaQuery(theme.breakpoints.up('lg')) ? '250px' : '100%',
  height: '45px',
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  variant: 'contained',
  boxShadow: theme.shadows[3],
}));

function CallToActionButtonGroup({ justifyContent, variant }) {
  const [open, setOpen] = useState(false);
  const toggleDialog = () => { setOpen(!open); };
  return (
    <Stack width="100%" height="100%" direction="row" justifyContent={justifyContent || 'align-left'} spacing={2}>
      {variant === 'orange' ? <OrangeButton onClick={toggleDialog}>Try free today</OrangeButton> : <GreenButton onClick={toggleDialog}>Try free today</GreenButton>}
      <InvertedButton href="https://calendly.com/marlin-code/marlin-demo">Schedule a demo</InvertedButton>
      <TryTodayDialog open={open} onClose={toggleDialog} />
    </Stack>
  );
}

export default CallToActionButtonGroup;
