import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = responsiveFontSizes(createTheme({
  palette: {
    primary: {
      light: '#4bacab',
      main: '#007c7c',
      dark: '#004f50',
      contrastText: '#EBFFFF',
      mainGradient: 'linear-gradient(#007c7c, #319898, #99D1D1, #EBFFFF)',
    },
    secondary: {
      light: '#ffa254',
      main: '#ff7124',
      dark: '#c54000',
      contrastText: '#fff8e2',
      horizontalGradient: 'linear-gradient(to right, #C54000, #ff7124)',
    },
  },
}));
export default theme;
