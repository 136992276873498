import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import React from 'react';
import ModuleCard from './ModuleCard';
import ModuleCardConfig from './ModuleCardConfig';

function ModuleGallery() {
  return (
    <Box flexGrow={1}>
      <Grid container spacing={4}>
        {
          ModuleCardConfig.map((config) => (
            <Grid key={config.title} xs={12} lg={4}>
              <ModuleCard config={config} />
            </Grid>
          ))
        }
      </Grid>
    </Box>
  );
}

export default ModuleGallery;
