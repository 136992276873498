import {
  Button,
  Stack,
  Typography,
  IconButton,
  useMediaQuery,
  Drawer,
  ListItem,
  List,
  ListItemText,
  Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { HashLink as Link } from 'react-router-hash-link';
import { styled, useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import SectionContainer from '../../components/SectionContainer';

const StyledTypograph = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 24,
}));

const StyledLink = styled(Link)(({ _theme }) => ({
  textDecoration: 'none',
}));

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsMenuOpen(open);
  };
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <SectionContainer height="72px" sx={{ paddingY: 0, backgroundColor: theme.palette.primary.contrastText }}>
      <Stack height="100%" direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={8}>
          <StyledLink smooth to="/">
            <Typography
              fontSize={48}
              color={theme.palette.primary.main}
            >
              Marlin
            </Typography>
          </StyledLink>
          {
          isDesktop && (
            <Stack direction="row" spacing={4}>
              <a href="https://marlincode.notion.site/" style={{ textDecoration: 'none' }}>
                <StyledTypograph>Docs</StyledTypograph>
              </a>
              <StyledLink smooth to="#modules">
                <StyledTypograph>Modules</StyledTypograph>
              </StyledLink>
              <StyledLink smooth to="#contact">
                <StyledTypograph>Contact</StyledTypograph>
              </StyledLink>

            </Stack>
          )
}
        </Stack>
        {
          isDesktop && (
            <Button variant="contained" color="primary">
              Try free today
            </Button>
          )
        }
        {
          !isDesktop && (
          <IconButton aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          )
        }
        {
          !isDesktop && (
            <Drawer
              anchor="right"
              open={isMenuOpen}
              onClose={toggleDrawer(false)}
              PaperProps={{ sx: { backgroundColor: theme.palette.primary.dark } }}
            >
              <Stack direction="column">
                <List>
                  <ListItem key="Docs">
                    <a href="https://marlincode.notion.site/" style={{ textDecoration: 'none' }}>
                      <ListItemText primary="Docs" sx={{ color: theme.palette.primary.contrastText }} />
                    </a>
                  </ListItem>
                  <ListItem key="modules">
                    <StyledLink onClick={toggleDrawer(false)} smooth to="#modules">
                      <ListItemText primary="Modules" sx={{ color: theme.palette.primary.contrastText }} />
                    </StyledLink>
                  </ListItem>
                  <ListItem key="contact">
                    <StyledLink onClick={toggleDrawer(false)} smooth to="#contact">
                      <ListItemText primary="Contact" sx={{ color: theme.palette.primary.contrastText }} />
                    </StyledLink>
                  </ListItem>
                  <ListItem key="Try Today">
                    <ListItemText primary="Try Today" sx={{ color: theme.palette.primary.contrastText }} />
                  </ListItem>
                </List>
                <Divider variant="middle" sx={{ borderColor: theme.palette.secondary.contrastText }} />
              </Stack>
            </Drawer>
          )
        }
      </Stack>
    </SectionContainer>
  );
}

export default Header;
