import {
  Typography, Stack, Paper, Button,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import React from 'react';

const ModuleStartButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderColor: theme.palette.primary.main,
  variant: 'contained',
  boxShadow: theme.shadows[3],
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));
function ModuleCard(props) {
  const {
    config: {
      img,
      title,
      body,
      docs,
    },
  } = props;

  const theme = useTheme();
  return (
    <Paper elevation={3}>
      <Stack direction="column" padding="24px" spacing={4}>
        <Stack spacing={2} direction="row" alignItems="center">
          <img src={img} height="48px" width="48px" alt={title} />
          <Typography
            variant="h4"
            fontWeight={700}
            color={theme.palette.primary.dark}
          >
            {title}
          </Typography>
        </Stack>
        <Typography
          variant="body1"
          fontWeight={400}
          color={theme.palette.primary.dark}
        >
          {body}
        </Typography>
        <ModuleStartButton href={docs}>Try today</ModuleStartButton>
      </Stack>
    </Paper>
  );
}

export default ModuleCard;
