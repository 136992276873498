import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const getPadding = (theme) => {
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  return isLarge ? '160 96' : '80 24';
};
const SectionContainer = styled(Box, {
  shouldForwardProp: (_prop) => true,
})(({ theme }) => ({
  flexGrow: 1,
  padding: getPadding(theme),
}));

export default SectionContainer;
