import React from 'react';
import { Typography, Stack, useMediaQuery } from '@mui/material';
import { HashLink as Link } from 'react-router-hash-link';
import { styled, useTheme } from '@mui/material/styles';
import GithubLogo from '../../assets/GithubLogo.svg';
import TwitterLogo from '../../assets/TwitterLogo.svg';
import SectionContainer from '../../components/SectionContainer';

const StyledLink = styled(Link)(({ _theme }) => ({
  textDecoration: 'none',
}));

function Footer() {
  const theme = useTheme();
  const textDirection = useMediaQuery(theme.breakpoints.up('lg')) ? 'row' : 'column';
  return (
    <SectionContainer sx={{ paddingY: '24px', backgroundColor: theme.palette.primary.dark }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction={textDirection} spacing={4}>
          <Typography
            variant="caption"
            color={theme.palette.primary.contrastText}
          >
            Marlin, Inc.
          </Typography>
          <StyledLink to="/terms">
            <Typography
              variant="caption"
              color={theme.palette.primary.contrastText}
            >
              Terms
            </Typography>
          </StyledLink>
          <StyledLink to="/privacy">
            <Typography
              variant="caption"
              color={theme.palette.primary.contrastText}
            >
              Privacy
            </Typography>
          </StyledLink>
        </Stack>
        <Stack direction="row" spacing={4}>
          <a href="https://github.com/Marlin-Code" style={{ textDecoration: 'none' }}>
            <img src={GithubLogo} height="48px" width="48px" alt="github" />
          </a>
          <a href="https://twitter.com/MarlinCodeTech" style={{ textDecoration: 'none' }}>
            <img src={TwitterLogo} height="48px" width="48px" alt="twitter" />
          </a>
        </Stack>
      </Stack>
    </SectionContainer>
  );
}

export default Footer;
