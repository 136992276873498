import React from 'react';
import { Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import SectionContainer from '../../components/SectionContainer';

import CallToActionButtonGroup from '../../components/CallToActionButtonGroup';
import content from './BottomCTAContent.json';

function CallToAction() {
  const theme = useTheme();
  return (
    <Stack direction="column" spacing={4} alignItems="left">
      <Typography
        variant="h2"
        fontWeight={700}
        color={theme.palette.primary.dark}
      >
        { content.building }
      </Typography>
      <Typography
        variant="h4"
        fontWeight={400}
        color={theme.palette.primary.dark}
      >
        { content.future }
      </Typography>
      <CallToActionButtonGroup variant="orange" />
    </Stack>
  );
}

function BottomCTA() {
  const theme = useTheme();
  return (
    <SectionContainer sx={{ backgroundColor: theme.palette.primary.contrastText }}>
      <Grid container>
        <Grid xs={12} lg={8}>
          <CallToAction />
        </Grid>
      </Grid>
    </SectionContainer>
  );
}

export default BottomCTA;
